<template>
    <v-app>
      <v-card class="bgs rounded-0 d-flex justify-center" flat width="100%" height="100%">
        <v-container>
            <h2 class="text-center mt-6 font24 mb-4">
          {{ $t("Taoify消费协议") }}
        </h2>
        <p class="content mt-4">
          {{ $t("欢迎使用 Taoify ！") }}
        </p>
        <p class="content mt-4">
          {{ $t("为维护您自身权益，建议您仔细阅读各条款具体表述，并充分理解各条款的内容。如您对条款内容有任何疑问，可以向 Taoify 的客服和相关业务部门进行咨询。") }}
        </p>
<h3 class="titlee">{{ $t("一、消费须知") }}</h3>
<p class="content">
  {{ $t("Taoify 将向用户提供 Taoify 官网所展示的电商建站服务、相关的技术支持以及网络支持等服务。在服务的开通或使用过程中，您将支付您所订阅的在线服务费用和其他使用费用，具体包括但不限于店铺版本订阅、店铺佣金、应用版本订阅、应用产品、应用佣金等。") }}
</p>
<p class="content">
  {{ $t("所有服务费用都将在订购页面予以列明公示，您可自行选择具体服务类型并按列明的价格予以支付。关于您在 Taoify 平台上产生的所有费用信息，您可以登录店铺后台，依次点击「后台设置」>「账单」>「账单管理」进行查看。") }}
</p>

<h3 class="titlee">{{ $t("二、服务费用与付费方式") }}</h3>
<h4 class="subtitle">{{ $t("1. 服务费用") }}</h4>
<p class="content">
  {{ $t("您可以按照您的需求，根据方案计价选择相应的套餐类型，并根据所选择的套餐类型支付服务费用。") }}
</p>
<h4 class="subtitle">{{ $t("2. 付费方式") }}</h4>
<h5 class="subsubtitle">{{ $t("2.1 先付费") }}</h5>
<p class="content">
  {{ $t("2.1.1 在您付费之后，Taoify 才开始为您提供服务。若因付款异常导致支付中止，您可在商城「后台设置」>「账单」>「账单管理」中查看处理结果，并手工完成支付。") }}
</p>
<p class="content">
  {{ $t("2.1.1 服务期满双方愿意继续合作的，建议您在服务期满前7天内支付续费款项，以使服务得以继续进行。") }}
</p>
<h5 class="subsubtitle">{{ $t("2.2 后付费") }}</h5>
<p class="content">
  {{ $t("2.2.1 您可以先使用 Taoify 为您提供的服务，一定账期周期后，平台再清算您店铺内所产生的服务费用，并通过线下交易的形式向您收取服务费用。") }}
</p>
<p class="content">
  {{ $t("2.2.2 本方式仅向特定店铺开放，您需要与 Taoify 平台签约并开通预付模式。具体的和Taoify客服进行沟通，并签订预付模式协议。") }}
</p>
<p class="content">
  {{ $t("2.2.3 后付费的具体流程:Taoify 在每月结束后通过 Taoify 客服通知您的账单；如您对账单和发票有任何异议的，应于每月15日之前向 Taoify 客服书面提出，未在上述期限提出书面异议的，视为认可该账单及发票。") }}
</p>

<h3 class="titlee">{{ $t("三、费用支付") }}</h3>
<ul>
  <li>{{ $t("1. 您必须向我们提供有效可用的支付方式，以避免扣款失败影响店铺使用。建议您在「后台设置」>「账单」>「套餐与支付」>「支付方式管理」中绑定多张信用卡并授权使用于账单支付。账单支付的技术由第三方专业金融公司集成并合作，Taoify 平台不保存任何敏感的支付信息，请放心使用。") }}</li>
  <li>{{ $t("2. 账单出账后系统将按照您在授权的支付方式(“授权卡”)自动发起扣款，直到服务终止。若您设置了默认卡，系统优先使用默认卡发起扣款。除非另有说明，所有费用和其他收费均以美元计，所有付款均以美元计价。") }}</li>
  <li>{{ $t("3. 费用将显示在账单上，并通过提供的电子邮件发送给账户所有者。如果您对账单存在疑问，可以与 Taoify 的相关业务部门进行联系。后续将通过电子邮件的形式与您取得联系，账单争议内容将发送至您所提供的店铺财务邮箱，您可以在后台「店铺设置」>「系统设置」>「基础设置」>「店铺信息」中进行配置或修改。") }}</li>
  <li>{{ $t("4. 如果您有开票需求，可以与 Taoify 的相关业务部门进行联系。发票将通过以电子邮件发送给至您所提供的店铺财务邮箱。") }}</li>
  <li>{{ $t("5. 订阅费是提前支付的，在您已订阅服务到期时间的基础上提前5个自然日，例如服务到期时间是2022-01-06 23:59:59，则您订阅服务的预计续费时间为2022-01-01 23:59:59。") }}</li>
  <li>{{ $t("6. 如果我们无法向授权卡收取订阅费，我们将向账户所有者发送一封电子邮件，告知付款失败并提醒更新信用卡。我们每隔24小时将再次尝试向授权卡收费，最多进行三次收费尝试（含首次收费尝试）。在第三次收费失败后，我们可暂停和撤销对您账户的访问权限。在您支付全部未结费用以及下一个账单周期的使用费用后，您的账户将重新激活，恢复访问权限。") }}</li>
  <li>{{ $t("7. 如果自暂停之日起60天内仍未支付未结清费用，Taoify 保留终止您帐户的权利。") }}</li>
  <li>{{ $t("8. 所有费用均不包括政府征收的相关税费、费用或收费（以下简称“税费”）。您须负责支付因订阅或购买Taoify产品和服务而产生的所有适用税费。在 Taoify 的税费收取范围内，税费根据您向我们提供的账单地址的适用税率计算。此类费用是此类产品和服务的费用之外的额外费用，将向您的授权卡收取。如果您可以免缴此类税金，您必须向我们提供您的免税证明，在某些管辖区内，免税证明包括可证明您符合免税资格且符合适用法律要求的原始证书。免税仅在 Taoify 收到符合条件的免税证据之日起适用。如果 Taoify 未向您收取税费，则应由您负责确定是否应缴纳税费，如果应缴纳税费，应自行向您所在管辖区内的相应税务机关缴税。") }}</li>
  <li>{{ $t("9. 除了 Taoify 向您收取并代您汇给相应税务机关的税款外，法律要求的任何扣除或预扣均应由您承担并单独支付给相关税务机关。Taoify 有权向您的授权卡收取本服务条款规定的全部费用，而不考虑可能需要的任何此类扣除或预扣。") }}</li>
  <li>{{ $t("10. 您应自行负责缴纳因您的 Taoify 商店的所有销售而产生的所有适用税费。") }}</li>
  <li>{{ $t("11. Taoify 不提供按月/按年订阅费、佣金、应用订阅等任何消费的退款。") }}</li>
  <li>{{ $t("12. Taoify 收取的佣金基于订单的付款状态，若由于后续订单产生退款等，不退还该笔订单的佣金。") }}</li>
</ul>

<h3 class="titlee">{{ $t("四、罚金政策") }}</h3>
<ul>
  <li>{{ $t("1. 超时发货的订单采取了严格的处罚措施，旨在维护交易秩序和保障消费者的权益。具体处罚措施：付款 30天内 没有上传物流单号，每单额外收取 0.5$ 罚金。为了避免这些处罚，商家应确保及时、准确地处理订单、并将物流单号同步到后台。") }}</li>
  <li>{{ $t("2. 对于定制类、预售等可能需要超过30天的类目，可向 Taoify 提出申请调整超时时间。") }}</li>
  <li>{{ $t("3. 系统只提供基础建站功能，不涉及买家与商家的资金处理与结算，资金相关的服务由第三方支付机构处理。") }}</li>
  <li>{{ $t("4. 系统若长期检测到店铺有大量超时发货的订单，Taoify 有权停止店铺。") }}</li>
</ul>

<h3 class="titlee">{{ $t("五、服务到期或欠款") }}</h3>
<h4 class="subtitle">{{ $t("1. 服务到期") }}</h4>
<h5 class="subsubtitle">{{ $t("1.1 按时计费") }}</h5>
<p class="content">
  {{ $t("1.1.1 若您使用的是免费试用版服务，本条款下服务期限，自 Taoify 为您开通相应服务之日起算。") }}
</p>
<p class="content">
  {{ $t("1.1.2 若您使用的是付费版服务，本条款下服务期限，自您支付服务费用之日起计算，若您系通过线下或其他方式购买 Taoify 套餐并支付费用，则本条款下服务期限自套餐激活之日起计算。具体服务的到期时间以具体页面内的提示为准。") }}
</p>
<h5 class="subsubtitle">{{ $t("1.2 按量计费") }}</h5>
<p class="content">
  {{ $t("1.2.1 若您使用的是先付费服务，即先购买服务次数/条数再使用的服务项目如物流跟踪应用产品，当服务次数/条数在使用过程消耗至零时，即视为服务到期。") }}
</p>
<p class="content">
  {{ $t("1.2.2 若您使用的是后付费服务，即先使用再付费的服务项目如店铺交易佣金，当服务累计使用到一定额度时，系统将按照计价方案自动生成费用账单，并向您设置的支付方式发起扣款，此类服务不涉及服务到期。") }}
</p>
<h4 class="subtitle">{{ $t("2. 服务欠款") }}</h4>
<p class="content">
  {{ $t("您应按照所选套餐类型支付相应服务的开通及使用费用。如您未按 Taoify 付款公告及时付款或欠费，Taoify 有权停止提供服务、关闭您的 Taoify 账号甚至终止本次建站合作，并要求您继续支付未结清的相关费用。") }}
</p>

<h3 class="titlee">{{ $t("六、服务的取消与终止") }}</h3>
<ul>
  <li>{{ $t("1. 您可以随时通过发送电子邮件到取消您的账户并终止服务条款，然后按照 Taoify 在回复邮件中的具体说明进行操作。") }}</li>
  <li>{{ $t("2. 任何一方由于任何原因终止服务：i.Taoify 将停止向您提供服务，您将不能再访问您的账户；ii.除非服务条款另有规定，否则您无权按比例或以其他方式获得任何费用的退款；在终止生效日期之前，您因使用 Taoify 服务而拖欠 Taoify 的任何未付账款将立即到期并需全额支付；iii.您的 Taoify 店铺将被关闭下线。") }}</li>
  <li>{{ $t("3. 服务终止后，您需要与域名供应商处理有关域名的全部事宜。") }}</li>
  <li>{{ $t("4. 服务终止之日，如果您有任何未结费用，您将通过电子邮件收到一份最终账单。在该账单全额付清后，我们不会再向您收取费用。") }}</li>
  <li>{{ $t("5. 我们保留根据法律法规变化、优化服务等需要，不时修改、补充服务条款、消费相关政策、使用协议、用户协议及隐私政策等 Taoify 条款、修改或终止 Taoify 服务和/或您的账户的权利，终止不影响终止日期之前产生的任何权利或义务。") }}</li>
  <li>{{ $t("6. 欺诈：如果我们怀疑您涉嫌任何欺诈行为（通过判决、和解、保险、托管调查或其他方式），Taoify 可暂停或终止您的账户，且上述行为不影响 Taoify 采取任何其他补救措施。") }}</li>
</ul>

<h3 class="titlee">{{ $t("七、服务和服务费用的修改") }}</h3>
<ul>
  <li>{{ $t("1. Taoify 平台将提前30天通知服务费用可能会发生变化。Taoify 可随时以公告形式将更改发布到 Taoify 网站或您的 Taoify 店铺后台。") }}</li>
  <li>{{ $t("2. Taoify 平台保留在不通知情况下，随时和不时修改或终止服务（或其任何部分或内容）的权利（除非所适用的法律另有规定）。") }}</li>
  <li>{{ $t("3. Taoify 平台不会就关于服务的任何修改、价格变动、暂停或终止对您或任何第三方承担任何责任。") }}</li>
</ul>

<h3 class="titlee">{{ $t("八、第三方服务") }}</h3>
<ul>
  <li>{{ $t("1. Taoify 可时常向您推荐和提供第三方软件、应用程序（以下简称“应用”）、产品、服务的接口、网站链接的访问权限或启用这些服务，供您考虑或使用。您购买、访问或使用任何此类第三方服务将是您与相应第三方服务提供商（以下简称“第三方提供商”）之间的往来。 除了 Taoify 服务条款之外，您还同意遵守您从第三方提供商处购买或由第三方提供商提供的服务所适用的特定服务附加条款。") }}</li>
  <li>{{ $t("2. 对于使用 Taoify 网站提供的第三方服务产生的风险，完全由您自行承担，您有责任在使用此类第三方服务前先阅读适用的条款和条件及相关隐私政策。") }}</li>
  <li>{{ $t("3. 我们不提供有关第三方服务的任何保证或陈述。您认可 Taoify 平台无法控制第三方服务，Taoify 对使用此类第三方服务的任何人不提供承诺也不承担任何责任。在 Taoify 网站（包括应用商店）上提供第三方服务、将此类第三方服务与 Taoify 自身服务集成、启用此类第三方服务均不构成或暗示 Taoify 对该服务的认可、授权、推荐或与其有从属关系。Taoify 不保证第三方服务的可用性，Taoify 可能随时自行决定禁止访问任何第三方服务，且无需通知您。Taoify 不会因为暂停访问或禁用某第三方服务对任何人承担任何责任。Taoify 强烈建议您在使用第三方服务前先寻求专家建议，以确保它们满足您的需求。") }}</li>
  <li>{{ $t("4. 如果您在系统使用过程中，安装或启用了第三方服务并将其与 Taoify 服务配合使用，则意味着您授权我们允许第三方提供商访问您的数据和其他材料，并采取必要措施确保第三方服务和 Taoify 服务之间的交互操作。任何您与第三方提供商之间的数据交换和其他交互仅限于您与此类第三方供应商之间。Taoify 不对您的数据或其他材料的任何披露、修改或删除承担任何责任，也不对您因第三方服务或第三方提供商访问您的数据或其他材料而遭受的任何相应损失或损害承担任何责任。") }}</li>
  <li>{{ $t("5. 您与任何第三方服务商之间的关系严格限定在您与第三方服务商之间，Taoify 没有义务介入您与第三方服务商之间发生的任何争议。") }}</li>
  <li>{{ $t("6. 在任何情况下，Taoify 均不对您与任何第三方服务或第三方提供商之间的合作关系所造成的任何直接、间接、偶然、特殊、惩罚性、或其他损害负责。即使已向 Taoify 告知可能产生此类损害赔偿，这些责任限制依然在适用法律允许的最大范围内适用。") }}</li>
  <li>{{ $t("7. 如果您在使用第三方服务时与第三方提供商发生了利益纠纷，您须保证 Taoify 公司及其关联方、Taoify 的合作伙伴、Taoify 的高级职员、董事、代理人、员工和供应商免于任何第三方提出的起诉和损害赔偿要求，并同意赔偿我们已支付的相关诉讼费和损害赔偿，包括合理的律师费。") }}</li>
</ul>
          <p class="content">
            © Taoify 2024 
          </p>
        </v-container>
      </v-card>
    </v-app>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="scss" scoped>
  .titlee {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    line-height: 25px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .content {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
  }
  li {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: #6a6e79;
    margin-left: 20px;
    list-style: initial;
  }
  </style>